/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

function SectionProject() {
  return (
    <>
      <div
        className="section section-project section-dark-blue cd-section"
        id="projects"
      >
        <div className="project-4 section section-dark-blue">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2>Projects</h2>
              </Col>
            </Row>
            <div className="space-top" />
            <br />
            <br />
            <Row>
              <Col className="ml-auto" md="5">
                <Card
                  className="mx-auto"
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/focus-time1.png") + ")",
                  }}
                ></Card>
              </Col>
              <Col className="mr-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">React Native</h6>
                    <div>
                      <CardTitle tag="h3" className="font-weight-normal">
                        FocusTime
                      </CardTitle>
                    </div>
                    <p className="card-description font-weight-normal">
                      A simple pomodoro technique inspired mobile application
                      that helps focus on a task with a customizable timer.
                    </p>
                    <CardFooter>
                      <a
                        href="https://snack.expo.io/@kawikarob/focustime"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="tumblr">
                          <i className="fa fa-globe" />
                        </Button>
                      </a>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col className="ml-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">React Native</h6>
                    <div>
                      <CardTitle tag="h3" className="font-weight-normal">
                        E-shop
                      </CardTitle>
                    </div>
                    <p className="card-description font-weight-normal">
                      A Full stack mobile e-commerce application that includes
                      uploading, updating, and managing products and orders. The
                      backend was built on Node.js with an Express server that
                      connects to a MongoDB database.
                    </p>
                    <CardFooter>
                      <a
                        href="https://github.com/kawikarob/eshop"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="github">
                          <i className="fa fa-github-alt" />
                        </Button>
                      </a>
                      <a href="#" rel="noopener noreferrer" target="_blank">
                        <Button className="btn-just-icon mr-1" color="tumblr">
                          <i className="fa fa-globe" />
                        </Button>
                      </a>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>

              <Col className="mr-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/e-shop1.png") + ")",
                  }}
                ></Card>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col className="ml-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/quote1.png") + ")",
                  }}
                ></Card>
              </Col>
              <Col className="mr-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">React</h6>
                    <div>
                      <CardTitle tag="h3" className="font-weight-normal">
                        Quote Saver
                      </CardTitle>
                    </div>
                    <p className="card-description font-weight-normal">
                      A simple quote saving application to keep a user's
                      favorite quotes in one place. The application uses
                      Node.js, Express, and MySQL for the backend.
                    </p>
                    <CardFooter>
                      <a
                        href="https://github.com/kawikarob/quote-app"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="github">
                          <i className="fa fa-github-alt" />
                        </Button>
                      </a>
                      <a
                        href="https://nameless-taiga-84412.herokuapp.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="tumblr">
                          <i className="fa fa-globe" />
                        </Button>
                      </a>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col className="ml-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">React </h6>
                    <div>
                      <CardTitle tag="h3" className="font-weight-normal">
                        White Bear
                      </CardTitle>
                    </div>
                    <p className="card-description font-weight-normal">
                      A flash card application that aids in memorization using
                      memorable imagery. The backend for this applaction was
                      built with Node.js, Express, and MySQL
                    </p>
                    <CardFooter>
                      <a
                        href="https://github.com/kawikarob/white-bear-fullstack"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="github">
                          <i className="fa fa-github-alt" />
                        </Button>
                      </a>
                      <a
                        href="https://dry-spire-89924.herokuapp.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button className="btn-just-icon mr-1" color="tumblr">
                          <i className="fa fa-globe" />
                        </Button>
                      </a>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>

              <Col className="mr-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/white-bear.png") + ")",
                  }}
                ></Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionProject;
