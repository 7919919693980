import React from "react";
import emailjs from "emailjs-com";

import { Button, Form, Input, Container, Row, Col } from "reactstrap";

function ContactMe() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_25qlshq",
        "template_zrw5qbo",
        e.target,
        "user_ngdtMhRkt9NJWhPX0YWm4"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div id="contact">
      <div
        className="page-header"
        style={{
          backgroundColor: "#1D1F29",
        }}
      >
        <div>
          <Container>
            <Row className="align-items-center">
              <Col className="mx-auto text-center " md="8">
                <br />
                <br />
                <h2 className="title">Contact me</h2>
              </Col>
            </Row>
            <Row>
              <Col className="mx-auto text-center" md="6">
                <a
                  href="https://www.linkedin.com/in/kawika-robertson-0b34971b3/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button className="btn-just-icon mr-1" color="linkedin">
                    <i className="fa fa-linkedin" />
                  </Button>
                </a>
                <a
                  href="https://github.com/kawikarob"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button className="btn-just-icon mr-1" color="instagram">
                    <i className="fa fa-github-alt" />
                  </Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="mx-auto text-center" md="6">
                <Form onSubmit={sendEmail} className="contact py-4">
                  <Row>
                    <Col md="6">
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="first_name"
                        required
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="last_name"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        required
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        placeholder="Subject"
                        type="text"
                        name="subject"
                        required
                      />
                    </Col>
                  </Row>
                  <Input
                    placeholder="Message"
                    rows="7"
                    type="textarea"
                    name="message"
                    required
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        type="submit"
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
