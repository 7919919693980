import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

function ProfilePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <div id="about">
      <ProfilePageHeader />
      <div className="wrapper">
        <div className="profile-content section section-black">
          <Container>
            <Row>
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  {/* <div className="fileinput-new img-no-padding">
                    <img
                      alt="..."
                      src={require("assets/img/faces/kawika2.jpeg")}
                    />
                  </div> */}
                  <div>
                    <h4 className="text-center text-white font-weight-bold mb-2">
                      Kawika{" "}
                      <span className="font-weight-normal font-italic">
                        (kuh-VEE-kuh)
                      </span>
                    </h4>
                    <h6 className="text-center text-white font-weight-normal">
                      Las Vegas, NV
                    </h6>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p className="text-white font-weight-normal">
                  I am a passionate Full Stack Developer who loves to learn and
                  always looking to improve my skillset and grow as a developer.
                  Currently, I am insterested in mobile developement.
                </p>
                <br />

                <p className="text-white font-weight-bold mt-2">
                  Interests:{" "}
                  <span className="font-weight-normal ml-1">
                    working out, yoga, snowboarding, music, traveling, cars,
                    video games
                  </span>
                </p>
                <br />
                <Button
                  className="btn-default text-center"
                  color="info"
                  href="https://docs.google.com/document/d/15vMrcG5d6Ie_Hz-icmm5PEHIJuirLw_QRsv4UKkifrk/edit?usp=sharing"
                  target="_blank"
                >
                  Resume
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
