import React from "react";

import ColorNavbar from "components/Navbars/ColorNavbar.js";
import Home from "./Home";
import AboutMe from "views/AboutMe";
import ContactMe from "./ContactMe";
import Projects from "./Projects";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <Home />
      <AboutMe />
      <Projects />
      <ContactMe />
    </>
  );
}

export default Index;
