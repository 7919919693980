import React from "react";
import Typewriter from "typewriter-effect";

function Home() {
  return (
    <div id="home">
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/cover-blkwht.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="float-left offset-lg-2">
            <h2 className="text-center font-weight-bold">
              Hi, my name is{" "}
              <span className="text-info font-weight-bold display-3">
                Kawika
              </span>
            </h2>
            <h2>
              <Typewriter
                options={{
                  strings: [
                    "Your future developer",
                    "The one you're looking for",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
