import React from "react";

import classnames from "classnames";
import Headroom from "headroom.js";

import { Collapse, Navbar, NavItem, Nav, Container } from "reactstrap";
import { Link } from "react-scroll";

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("navbar-transparent");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          {/* <div className="navbar-translate">
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div> */}
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto mr-4" navbar>
              <NavItem className="text-info">
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  duration={1000}
                  className="btn btn-link"
                >
                  Home
                </Link>

                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  duration={1000}
                  className="btn btn-link"
                >
                  About
                </Link>

                <Link
                  activeClass="active"
                  to="projects"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  duration={1000}
                  className="btn btn-link"
                >
                  Projects
                </Link>

                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  duration={1000}
                  className="btn btn-link"
                >
                  Contact
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
